// const handle1 = 'KesleyDavid';
// const handle2 = 'kesleydavid';
// const handle3 = 'KesleyDEV';
const handleMail = 'vendas@eletrolumens.com';
const handlePhone = '+55 62 3095 1212';
// const handleTwitter = 'kesley_david';
// const handleYoutube = 'UCtwG53vrewBT4L_nVnCor5w';
// const handleDiscord = 'vnwMJxp';

const links = [
  {
    label: "Wpp",
    url: `https://web.whatsapp.com/send?phone=+556230951212&text=`,
    value: `${handlePhone}`,
  },
  {
    label: "Telefone",
    url: `tel:vendas@eletrolumens.com`,
    value: `${handlePhone}`,
  },
  {
    label: "Email",
    url: `mailto:vendas@eletrolumens.com`,
    value: `${handleMail}`,
  },
  // {
  //   label: "Youtube",
  //   url: `https://www.youtube.com/user/${handleYoutube}`,
  // },
  // {
  //   label: "Twitter",
  //   url: `https://twitter.com/${handleTwitter}`,
  // },
  // {
  //   label: "Behance",
  //   url: `http://be.net/${handle1}`,
  // },
  // {
  //   label: "Gitlab",
  //   url: `https://gitlab.com/${handle1}`,
  // },
  // {
]

export default links