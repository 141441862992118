import React from "react"

// import firebase from "gatsby-plugin-firebase"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Name from "../components/Name"

import Contacts from "../components/Contacts"

import * as S from '../styles/index';

const IndexPage = () => {
  // firebase
  //   .analytics()
  //   .logEvent("visited_about_us")

  // useEffect(() => {
  //   firebase.analytics()
  //   firebase.performance()
  // }, []);

  return (
    <Layout>
      <SEO title="Contato" description="Eletrolumens" />
      {/* <Link to="/contact">Contact</Link> */}
      <S.Container>
        <S.Main>
          <S.DivLogo>
            {/* <S.Logo>
              <Logo />
            </S.Logo> */}
            <S.Name>
              <Name />
            </S.Name>
          </S.DivLogo>
          <S.Separator />
          <S.Content>
            <S.DivTitle>
              <S.TextTitle>Contato</S.TextTitle>
              <S.TextTitleSub>Departamento Comercial</S.TextTitleSub>
            </S.DivTitle>
            <S.DivContacts>
              {/* <SocialLinks /> */}
              <Contacts />
            </S.DivContacts>
          </S.Content>
        </S.Main>
        {/* <S.Temp>
          <S.TextTemp>[ Site under development ]</S.TextTemp>
        </S.Temp> */}
        {/* <S.DivSkills>
          <Skills />
        </S.DivSkills> */}
      </S.Container>
    </Layout>
  )
}

export default IndexPage
